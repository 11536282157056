import { Flex, Image } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import React from 'react';

import Layout from '../../components/Layout';
import { WLActionableImage, WLContainer } from '../../design-library';
import BeYourOwnBossLargeImage from './assets/img-lg-be-your-own-boss.jpg';
import BusinessDevelopmentLargeImage from './assets/img-lg-business-development.jpg';
import GreatBenefitsLargeImage from './assets/img-lg-great-benefits.jpg';
import MakeADifferenceLargeImage from './assets/img-lg-make-a-difference.jpg';
import SupportToSucceedLargeImage from './assets/img-lg-support-to-succeed.jpg';
import BeYourOwnBossSmallImage from './assets/img-sm-be-your-own-boss.jpg';
import BusinessDevelopmentSmallImage from './assets/img-sm-business-development.jpg';
import GreatBenefitsSmallImage from './assets/img-sm-great-benefits.jpg';
import MakeADifferenceSmallImage from './assets/img-sm-make-a-difference.jpg';
import SupportToSucceedSmallImage from './assets/img-sm-support-to-succeed.jpg';

interface LifeCareerBalancePageProps extends PageProps<undefined> {}

const LifeCareerBalancePage: React.FC<LifeCareerBalancePageProps> = ({
  location,
}) => {
  return (
    <Layout
      seo={{
        title: 'Start Your Sales Career with WoodmenLife',
        description:
          'Get started on your new sales career with benefits for those who qualify and support to succeed. Learn how to be a part of it at WoodmenLife.',
      }}
      currentPath={location.pathname}
    >
      <WLContainer
        width="FULL_WIDTH"
        borderTopWidth="16px"
        borderTopColor="blue.300"
      >
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <WLActionableImage
            title="Be Your Own Boss"
            subText="Get paid for the work you do, not the hours you put in. In addition to your sales earnings, you'll have opportunities to earn recognition and incentives. Your family is important, so set your own schedule and be there for them. Get your life back!"
            href="/careers/sales/sales-career/your-career-your-way/"
            image={
              <>
                <Image
                  display={{ lg: 'none' }}
                  src={BeYourOwnBossSmallImage as string}
                  alt=""
                />
                <Image
                  display={{ base: 'none', lg: 'unset' }}
                  src={BeYourOwnBossLargeImage as string}
                  alt=""
                />
              </>
            }
            color="orange"
          />
          <WLActionableImage
            title="Great Benefits"
            subText="As a WoodmenLife Representative who meets production goals, you are eligible for a full benefits package."
            href="/careers/sales/benefits/"
            image={
              <>
                <Image
                  display={{ lg: 'none' }}
                  src={GreatBenefitsSmallImage as string}
                  alt=""
                />
                <Image
                  display={{ base: 'none', lg: 'unset' }}
                  src={GreatBenefitsLargeImage as string}
                  alt=""
                />
              </>
            }
            color="green"
          />
          <WLActionableImage
            title="Support to Succeed"
            subText="You will receive comprehensive training and support from the start. Managers, mentors and our dedicated Sales Development Team will help you succeed."
            href="/careers/sales/sales-career/training-and-support/"
            image={
              <>
                <Image
                  display={{ lg: 'none' }}
                  src={SupportToSucceedSmallImage as string}
                  alt=""
                />
                <Image
                  display={{ base: 'none', lg: 'unset' }}
                  src={SupportToSucceedLargeImage as string}
                  alt=""
                />
              </>
            }
            color="blue"
          />
          <WLActionableImage
            title="Business Development"
            subText="We offer financial assistance to qualifying new Representatives through programs designed to cushion the transition and help you meet you financial needs."
            href="/careers/sales/sales-career/big-money/"
            image={
              <>
                <Image
                  display={{ lg: 'none' }}
                  src={BusinessDevelopmentSmallImage as string}
                  alt=""
                />
                <Image
                  display={{ base: 'none', lg: 'unset' }}
                  src={BusinessDevelopmentLargeImage as string}
                  alt=""
                />
              </>
            }
            color="yellow"
          />
          <WLActionableImage
            title="Make a Difference"
            subText="Feel good about what you do by helping families prepare for their financial future and helping your community thrive through WoodmenLife's fraternal program."
            href="/extras/"
            image={
              <>
                <Image
                  display={{ lg: 'none' }}
                  src={MakeADifferenceSmallImage as string}
                  alt=""
                />
                <Image
                  display={{ base: 'none', lg: 'unset' }}
                  src={MakeADifferenceLargeImage as string}
                  alt=""
                />
              </>
            }
            color="red"
          />
        </Flex>
      </WLContainer>
    </Layout>
  );
};

export default LifeCareerBalancePage;
